import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import 'moment/locale/zh-tw';
import {
  makeStyles,
  createTheme,
  ThemeProvider,
} from '@material-ui/core/styles';
import theme from '../../theme';
import { getURLSearchParamByKey } from 'src/utils';

const customWeekdays = {
  tw: ['日', '一', '二', '三', '四', '五', '六'],
  en: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
  jp: ['日', '月', '火', '水', '木', '金', '土'],
};

export const DATE_FORMATTER = 'yyyy-MM-dd';
const MOMENT_DATE_FORMATTER = 'yyyy-MM-DD';
// https://github.com/mui-org/material-ui-pickers/issues/1901
const customTheme = createTheme({
  overrides: {
    MuiButtonBase: {
      root: {
        '&:focus': {
          border: 0,
          outline: 'none',
        },
      },
    },
    MuiPickersBasePicker: {
      pickerView: {
        maxWidth: 'unset',
      },
    },
    MuiPickersCalendarHeader: {
      transitionContainer: {
        height: '35px',
        '& .MuiTypography-body1': {
          fontSize: '1.25rem',
        },
      },
      daysHeader: {
        justifyContent: 'space-evenly',
      },
      dayLabel: {
        fontSize: '1.25rem',
      },
    },
    MuiPickersCalendar: {
      week: {
        justifyContent: 'space-evenly',
      },
    },
    MuiPickersDay: {
      day: {
        '& .MuiTypography-body2': {
          fontSize: '1.375rem',
        },
      },
      daySelected: {
        backgroundColor: theme.colors.primary,
        color: theme.colors.white,
        borderRadius: '20px',
        '&:hover': {
          backgroundColor: theme.colors.primary,
        },
        '& .MuiTypography-colorInherit': {
          color: theme.colors.white,
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        '&$focused $notchedOutline': {
          border: `solid 1px ${theme.colors.borderSecondary}`,
          borderWidth: 1,
        },
        '&:hover  $notchedOutline': {
          border: `solid 1px ${theme.colors.borderSecondary}`,
          borderWidth: 1,
        },
      },
    },
  },
});

const useStyles = makeStyles(() => ({
  root: {
    width: 160,
  },
  inputRoot: {
    padding: '0 12px 0 0',
    cursor: 'pointer',
  },
  input: {
    cursor: 'pointer',
    padding: '7px 0 7px 14px',
    fontSize: '1.125rem',
    height: 24,
    lineHeight: 1.6,
    color: theme.colors.textPrimary,
    letterSpacing: 0.08,
  },
  notchedOutline: {
    border: `solid 1px ${theme.colors.borderSecondary}`,
    borderRadius: 8,
  },
  popoverRoot: {
    marginTop: 5,
    borderRadius: 8,
  },
  icon: {
    color: theme.colors.textSecondary,
  },
}));

function DatePickerComp({ defaultDate, availableDates, handleChange }) {
  const locale = getURLSearchParamByKey('locale');
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = React.useState(new Date(defaultDate));

  const handleDateChange = (date) => {
    setSelectedDate(date);
    handleChange(date.toDate());
  };

  const handleClose = () => {
    handleChange(selectedDate);
  };

  useEffect(() => {
    setSelectedDate(defaultDate);
  }, [defaultDate]);

  class CustomMomentUtils extends MomentUtils {
    getWeekdays() {
      return customWeekdays[locale] || customWeekdays['tw'];
    }
  }

  return (
    <ThemeProvider theme={customTheme}>
      <MuiPickersUtilsProvider libInstance={moment} utils={CustomMomentUtils}>
        <DatePicker
          className={classes.root}
          disableToolbar
          id="date-picker"
          variant="static"
          format="YYYY/MM/DD"
          value={selectedDate}
          disablePast
          shouldDisableDate={(momentDate) => {
            const day = momentDate.format(MOMENT_DATE_FORMATTER);
            if (!availableDates[day]) {
              return true;
            }
            return false;
          }}
          onChange={handleDateChange}
          onClose={handleClose}
          PopoverProps={{
            PaperProps: {
              classes: {
                root: classes.popoverRoot,
              },
            },
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
          }}
        />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
}

DatePickerComp.propTypes = {
  handleChange: PropTypes.func,
  defaultDate: PropTypes.string,
};

DatePickerComp.defaultProps = {
  availableDates: [],
};

export default DatePickerComp;
