import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import HorizontalTwoActionFooter from '../Footer/HorizontalTwoActionFooter';
import { PAGE_NAME as LAST_PAGE } from './SelectPatientsPage';
import { PAGE_NAME as SUCCESS_PAGE } from './SuccessPage';
import { PAGE_NAME as FAILED_PAGE } from './FailedPage';
import {
  createAppointment,
  getHisSeqTimes,
  geOpenApiHisSeqTimes,
} from '../../services/appointment.service';
import { toDay, toDayOfWeekNumberOnly, toMonth } from '../../utils/date';
import Menu from '../1TalkDesign/menu';
import Loading from '../1TalkDesign/Loading';
import { getBookingType } from 'src/utils';
import { BOOKING_TYPE } from 'src/utils/const';
import { t } from 'src/i18n/config';
import { isOpenApi } from 'src/services/utils';

const CMenu = styled(Menu)`
  margin-top: 8px;
`;
const Container = styled.div`
  margin-top: 8px;
  padding: 0 12px;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  margin: 16px 0;
  padding: 0 4px;
`;

const Subject = styled.div`
  font-size: 1.625rem;
  font-weight: 500;
  line-height: 1.33;
`;

const DoctorName = styled.div`
  margin-top: 8px;
  font-size: 1.375rem;
  font-weight: 500;
  line-height: 1.6;
`;

const LoadingWrapper = styled.div`
  display: flex;
  padding: 16px 0;
`;

const SectionContainer = styled.div`
  padding: 16px 4px;
  border-top: ${(props) => props.theme.boxBorder.primary};
  &:last-child {
    border-bottom: ${(props) => props.theme.boxBorder.primary};
  }
`;
const Title = styled.div`
  color: ${(props) => props.theme.colors.textSecondary};
`;
const Value = styled.div`
  margin-top: 8px;
  font-size: 1.375rem;
  font-weight: 500;
`;

function InfoSection({ title, value }) {
  return (
    <SectionContainer>
      <Title>{title}</Title>
      <Value>{value}</Value>
    </SectionContainer>
  );
}

ConfirmAppointment.defaultProps = {
  appointment: {
    patient: {},
    slot: {},
  },
  seqTimeMap: {},
};

function ConfirmAppointment({
  schedules,
  appointment,
  onChangeStep,
  seqTimeMap,
  isPreparingProps,
}) {
  const { subject, doctor, patient = {}, slot = {} } = appointment;
  const [seqTimes, setSeqTimes] = useState([]);
  const [selectedSeqTime, setSelectedSeqTime] = useState(seqTimes[0]);
  const [loading, setLoading] = useState(false);

  const selectedDate = new Date(slot.slotId);

  const handleSelect = (item) => {
    setSelectedSeqTime(item);
  };

  const handleBack = () => {
    onChangeStep(LAST_PAGE, { appointment, schedules });
  };

  const handleNext = async () => {
    const bookingType = getBookingType();
    if (bookingType === BOOKING_TYPE.TIME && selectedSeqTime == null) {
      // ignore action if no seqTime is selected
      return;
    }
    const {
      slot: { slotId, slotItemId },
      subject,
      patient,
    } = appointment;

    const { idNum, birth, name, phone, note, idType } = patient;

    const {
      doctorId,
      doctorName,
      roomId,
      roomID,
      roomName,
      scheduleID,
      subjectCode,
      subjectName,
      serviceStoreId,
      scheduleId,
    } = schedules[slotId][subject][doctor][slotItemId][0];

    const booking = isOpenApi()
      ? {
          scheduleID,
          idNum,
          idType: idType || 'twID',
          birthday: new Date(birth).getTime(),
          gender: idNum[1] === '1' ? 'M' : 'F',
          name,
          phone,
          note,
          ...(selectedSeqTime?.name && {
            appointmentTime: selectedSeqTime.name,
          }),
        }
      : {
          serviceStoreId,
          scheduleId,
          period: slotItemId,
          date: slotId,
          patientId: patient.id,
          subjectName,
          subjectCode,
          doctor: {
            doctorId,
            roomId,
            roomName,
            doctorName,
          },
          bookingType,
        };

    if (selectedSeqTime != null) {
      booking.time = selectedSeqTime.name;
      booking.no = selectedSeqTime.value;
    }

    setLoading(true);
    try {
      const booked = await createAppointment({
        ...booking,
        source: 'LineLiff',
      });
      onChangeStep(SUCCESS_PAGE, {
        ...appointment,
        roomID: roomName || roomID,
        roomId: roomName || roomId || roomID,
        booked,
      });
    } catch (error) {
      onChangeStep(FAILED_PAGE, {
        message: error.message,
        contact: error.contact,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    setSelectedSeqTime(seqTimes[0]);
  }, [seqTimes]);

  useEffect(() => {
    setSeqTimes(
      Object.keys(seqTimeMap).map((key) => {
        const { seq, time, appointmentTime } = seqTimeMap[key];
        return {
          id: `${seq}`,
          name: `${time || appointmentTime}`,
          value: `${seq}`,
        };
      })
    );
  }, [seqTimeMap]);

  return (
    <React.Fragment>
      <Container>
        <Header>
          <Subject>{subject}</Subject>
          <DoctorName>{doctor}</DoctorName>
        </Header>
        <InfoSection
          title={t('appointmentTime')}
          value={`${toMonth(selectedDate)} ${t('month')} ${toDay(
            selectedDate
          )} ${t('day')} (${toDayOfWeekNumberOnly(selectedDate)}) ${
            slot.title
          }`}
        ></InfoSection>
        {isPreparingProps ? (
          <LoadingWrapper>
            <Loading color="rgb(0, 109, 177)"></Loading>
          </LoadingWrapper>
        ) : (
          <React.Fragment>
            {selectedSeqTime != null && (
              <CMenu
                items={seqTimes}
                name={selectedSeqTime ? selectedSeqTime.name : undefined}
                onClickItem={handleSelect}
              />
            )}
          </React.Fragment>
        )}
        <InfoSection
          title={t('patientName')}
          value={
            patient.isSelf
              ? `${patient.name} ${t('yourself')}`
              : `${patient.name || ''} ${
                  patient.alias != null ? `(${patient.alias})` : ''
                }`
          }
        ></InfoSection>
      </Container>

      <HorizontalTwoActionFooter
        rightFunc={handleNext}
        rightText={t('next')}
        rightEndIcon={<i className="ri-arrow-right-s-line"></i>}
        rightLoading={loading}
        leftFunc={handleBack}
        leftText={t('back')}
        leftEndIcon={<i className="ri-arrow-left-s-line"></i>}
      ></HorizontalTwoActionFooter>
    </React.Fragment>
  );
}

export default ConfirmAppointment;
export const PAGE_NAME = 'ConfirmAppointment';

export const prepareProps = async (lastState) => {
  try {
    let seqTimeMap;
    const option = getBookingType();
    if (option === BOOKING_TYPE.TIME) {
      const { appointment, schedules } = lastState;
      const {
        slot: { slotId, slotItemId },
        doctor,
        subject,
      } = appointment;
      if (isOpenApi()) {
        const { scheduleID } =
          schedules[slotId][subject][doctor][slotItemId][0];
        seqTimeMap = await geOpenApiHisSeqTimes(scheduleID);
      } else {
        const { doctorId, roomId, subjectCode, scheduleId } =
          schedules[slotId][subject][doctor][slotItemId][0];
        seqTimeMap = await getHisSeqTimes(
          slotId,
          slotItemId,
          doctorId,
          roomId,
          subjectCode,
          scheduleId
        );
      }
    }

    return {
      ...lastState,
      seqTimeMap,
    };
  } catch (error) {
    console.error({ error });
    throw error;
  }
};
