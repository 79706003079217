import React, { useEffect, useState } from 'react';
import { format, addDays } from 'date-fns';
import styled from 'styled-components';
import DatePicker, { DATE_FORMATTER } from '../1TalkDesign/DatePicker';
import HorizontalTwoActionFooter from '../Footer/HorizontalTwoActionFooter';
import { getSchedules } from '../../services/appointment.service';
import { PAGE_NAME as NEXT_PAGE } from './SelectDoctorsPage';
import { getURLSearchParamByKey } from 'src/utils';
import { t } from 'src/i18n/config';

const PickerContainer = styled.div`
  margin-top: 24px;
  border: solid 1px rgba(0, 0, 0, 0.1);
`;

const getDefaultSelectedDate = (availableDates) => {
  const now = new Date();
  return Object.keys(availableDates)[0] != null &&
    new Date(Object.keys(availableDates)[0]) > now
    ? format(new Date(Object.keys(availableDates)[0]), DATE_FORMATTER)
    : format(new Date(), DATE_FORMATTER);
};
function SelectDate({ schedules, availableDates, onChangeStep }) {
  const [selectedDate, setDate] = useState(
    getDefaultSelectedDate(availableDates)
  );

  useEffect(() => {
    setDate(getDefaultSelectedDate(availableDates));
  }, [availableDates]);

  const handleChange = (date) => {
    setDate(format(date, DATE_FORMATTER));
  };

  const handleNext = () => {
    onChangeStep(NEXT_PAGE, { selectedDateString: selectedDate, schedules });
  };

  return (
    <React.Fragment>
      <PickerContainer>
        <DatePicker
          key={Object.keys(availableDates).length}
          defaultDate={selectedDate}
          handleChange={handleChange}
          availableDates={availableDates}
        ></DatePicker>
      </PickerContainer>
      <HorizontalTwoActionFooter
        rightFunc={handleNext}
        rightText={t('next')}
        rightEndIcon={<i className="ri-arrow-right-s-line"></i>}
      ></HorizontalTwoActionFooter>
    </React.Fragment>
  );
}

SelectDate.propTypes = {};
SelectDate.defaultProps = {
  schedules: {},
  availableDates: {},
};

export default SelectDate;

export const PAGE_NAME = 'SelectDate';
export const prepareProps = async () => {
  const disabledTodayReservation =
    getURLSearchParamByKey('disabledTodayReservation') === 'true';
  const reserveDays = getURLSearchParamByKey('reserveDay');
  const startDate = format(
    disabledTodayReservation ? addDays(new Date(), 1) : new Date(),
    'yyyy-MM-dd'
  );
  const endDate = reserveDays
    ? format(addDays(new Date(), reserveDays), 'yyyy-MM-dd')
    : '';
  const originalschedules = await getSchedules({ startDate, endDate });
  const schedules = Object.keys(originalschedules).reduce((s, t) => {
    s[format(new Date(t), DATE_FORMATTER)] = originalschedules[t];
    return s;
  }, {});
  const availableDates = Object.keys(schedules).reduce((s, t) => {
    s[t] = true;
    return s;
  }, {});

  return {
    schedules,
    availableDates,
  };
};
