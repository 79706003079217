import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import HorizontalTwoActionFooter from '../Footer/HorizontalTwoActionFooter';
import { toMonth, toDay } from '../../utils/date';
import TimeSlotCard from './TimeSlotCard';
import { PAGE_NAME as LAST_PAGE } from './SelectDoctorsPage';
import { PAGE_NAME as NEXT_PAGE } from './SelectPatientsPage';
import { t } from 'src/i18n/config';

const Wrapper = styled.div`
  height: 100%;
`;

const Container = styled.div`
  margin-top: 14px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 16px;
`;

const Subject = styled.div`
  font-size: 1.375rem;
  font-weight: 500;
  letter-spacing: 0.07px;
`;

const DoctorName = styled.div`
  font-weight: normal;
  margin-top: 8px;
`;

const Elevation = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 16px 0 24px;
  padding: 14px 18px 18px 18px;
  border-radius: 4px;
  border: ${(props) => props.theme.boxBorder.primary};
  background-color: #ffffff;
`;

const InformationIcon = styled.i`
  width: 20px;
  height: 20px;
  color: ${(props) => props.theme.colors.primary};
  font-size: 20px;
  margin: 0;
  padding: 0;
`;

const InformationContent = styled.div`
  padding: 4px 0;
  margin-left: 8px;
  color: ${(props) => props.theme.colors.textPrimary};
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.54;
  letter-spacing: 0.09px;
  color: ${(props) => props.theme.colors.textPrimary};
`;

const TimeSlots = styled.div`
  padding: 0 0 100px 0;
  display: flex;
  flex-direction: column;
`;

const EmptyBlock = styled.div`
  height: 100px;
`;

function SelectTimeSlots({
  onChangeStep,
  timeSlots: originalTimeSlots,
  schedules,
  appointment,
  hasReservationDetail,
}) {
  const slotRef = useRef(null);
  const [slots, setSlots] = useState(originalTimeSlots);
  const [selectedSlot, setSelectedSlot] = useState({});

  const handleBack = () => {
    onChangeStep(LAST_PAGE, {
      schedules,
      selectedDateString: appointment.date,
    });
  };

  const handleNext = () => {
    if (selectedSlot.slotId == null) {
      alert(t('selectTimeSlot'));
      return;
    }
    onChangeStep(NEXT_PAGE, {
      schedules,
      appointment: {
        ...appointment,
        slot: selectedSlot,
      },
    });
  };

  const handleSelect = (item) => {
    setSelectedSlot(item);
  };

  useEffect(() => {
    setSlots(originalTimeSlots);
  }, [originalTimeSlots]);

  useEffect(() => {
    if (slots && slotRef?.current && appointment?.date) {
      try {
        const date = new Date(appointment.date);
        const dateString = `${toMonth(date)} ${t('month')} ${toDay(date)} ${t(
          'day'
        )}`;
        const target = Array.from(
          slotRef.current.querySelectorAll('.TimeSlotCard_DateTitle')
        ).find((element) => element.innerText === dateString);
        if (target) {
          window.scrollTo(0, target.offsetTop - target.offsetHeight);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }, [appointment, slots, slotRef]);

  return (
    <Wrapper>
      <Container>
        <Subject>{appointment.subject}</Subject>
        <DoctorName>{appointment.doctor}</DoctorName>
        {hasReservationDetail && (
          <Elevation>
            <InformationIcon className="ri-information-fill"></InformationIcon>
            <InformationContent>{t('notAvailable')}</InformationContent>
          </Elevation>
        )}
      </Container>
      <TimeSlots ref={slotRef}>
        {slots &&
          slots.map(({ id, date, availables }) => {
            return (
              <TimeSlotCard
                key={id}
                id={id}
                selected={selectedSlot.slotId === id}
                date={date}
                availables={availables}
                onSelect={handleSelect}
              ></TimeSlotCard>
            );
          })}
        <EmptyBlock />
      </TimeSlots>
      <HorizontalTwoActionFooter
        rightFunc={handleNext}
        rightText={t('next')}
        rightEndIcon={<i className="ri-arrow-right-s-line"></i>}
        leftFunc={handleBack}
        leftText={t('back')}
        leftEndIcon={<i className="ri-arrow-left-s-line"></i>}
      ></HorizontalTwoActionFooter>
    </Wrapper>
  );
}

SelectTimeSlots.propTypes = {};

export default SelectTimeSlots;
export const PAGE_NAME = 'SelectTimeSlots';
export const prepareProps = async (lastState) => {
  const { appointment, schedules } = lastState;
  let hasReservationDetail = false;
  const timeSlots = Object.keys(schedules)
    .filter((date) => {
      return schedules[date][appointment.subject] != null;
    })
    .map((date) => {
      return {
        ...schedules[date][appointment.subject][appointment.doctor],
        date,
      };
    })
    .filter(({ date }) => {
      return schedules[date][appointment.subject][appointment.doctor] != null;
    })
    .map(({ date, morning, afternoon, evening }) => {
      hasReservationDetail = !!(
        morning[0]?.reservationDetail ||
        afternoon[0]?.reservationDetail ||
        evening[0]?.reservationDetail
      );

      return {
        id: date,
        date: new Date(date),
        availables: [
          {
            name: t('morning'),
            reservationDetail: morning[0]?.reservationDetail,
            id: 'morning',
            disabled: morning.length === 0 ? true : !morning[0].canReservation,
          },
          {
            name: t('afternoon'),
            reservationDetail: afternoon[0]?.reservationDetail,
            id: 'afternoon',
            disabled:
              afternoon.length === 0 ? true : !afternoon[0].canReservation,
          },
          {
            name: t('evening'),
            reservationDetail: evening[0]?.reservationDetail,
            id: 'evening',
            disabled: evening.length === 0 ? true : !evening[0].canReservation,
          },
        ],
      };
    });
  return { timeSlots, hasReservationDetail, ...lastState };
};
