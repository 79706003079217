import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button } from '../1TalkDesign/Buttons';
import theme from '../../theme';
import { toMonth, toDay, toDayOfWeek } from '../../utils/date';
import { getURLSearchParamByKey } from 'src/utils';
import { t } from 'src/i18n/config';

const Container = styled.div`
  border-bottom: ${(props) => props.theme.boxBorder.primary};
  padding: 0 16px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.white};
  box-sizing: border-box;
`;

const DateTitle = styled.div`
  margin-top: 24px;
`;
const TimeTitle = styled.div`
  color: ${(props) => props.theme.colors.textSecondary};
  font-size: 1rem;
  margin-top: 8px;
`;

const TimeSlotButton = styled(Button)`
  border: ${(props) =>
    props.selected
      ? props.theme.button.secondary.border
      : '1px solid ' + props.customizedfontcolor};
  color: ${(props) => (props.selected ? 'black' : props.customizedfontcolor)};
  margin: 16px 8px 24px 0;
  padding: 0 12px;
  height: 42px;
  min-width: 80px;
  font-size: 0.875rem;
  white-space: nowrap;
`;

function TimeSlotCard({
  id: slotId,
  selected: slotSelected,
  date,
  availables,
  onSelect,
}) {
  const hideDisableTimeSlot =
    getURLSearchParamByKey('hideDisableTimeSlot') === 'true';
  const [selected, setSelected] = useState();
  const handleSelect =
    ({ id: slotItemId, title }) =>
    () => {
      if (!slotSelected) {
        setSelected(slotItemId);
        onSelect({ slotId, slotItemId, title });
        return;
      }
      if (selected === slotItemId) {
        setSelected(undefined);
        onSelect({});
        return;
      }
      setSelected(slotItemId);
      onSelect({ slotId, slotItemId, title });
    };
  return (
    <Container>
      <DateTitle className="TimeSlotCard_DateTitle">
        {`${toMonth(date)} ${t('month')} ${toDay(date)} ${t('day')}`}
      </DateTitle>
      <TimeTitle>{toDayOfWeek(date)}</TimeTitle>
      {availables.map(({ id, disabled, name, reservationDetail }) => {
        if (hideDisableTimeSlot && disabled) return null;
        return (
          <TimeSlotButton
            key={id}
            selected={slotSelected && id === selected}
            disabled={disabled}
            customizedcolor={theme.colors.white}
            customizedfontcolor={
              !disabled ? theme.colors.default : theme.button.primary.disabled
            }
            onClick={handleSelect({ id, title: name })}
          >
            {name}
            {reservationDetail && '(' + reservationDetail + ')'}
          </TimeSlotButton>
        );
      })}
    </Container>
  );
}

TimeSlotCard.propTypes = {
  date: PropTypes.instanceOf(Date),
  availables: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
};

TimeSlotCard.defaultProps = {
  availables: [],
};
export default TimeSlotCard;
