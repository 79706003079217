import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Footer from '../Footer';
import { Button } from '../1TalkDesign/Buttons';
import Loading from '../1TalkDesign/Loading';
import theme from '../../theme';

const CFooter = styled(Footer)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 8px;
`;
const RightButton = styled(Button)`
  margin: 12px 16px;
  visibility: ${(props) => (props.hidden ? 'hidden' : 'unset')};
`;
const LeftButton = styled(Button)`
  margin: 12px 16px;
  visibility: ${(props) => (props.hidden ? 'hidden' : 'unset')};
`;
function HorizontalTwoActionFooter({
  rightFunc,
  rightText,
  rightEndIcon,
  rightLoading,
  leftFunc,
  leftText,
  leftEndIcon,
}) {
  return (
    <CFooter>
      <LeftButton
        customizedcolor={theme.colors.white}
        customizedfontcolor={theme.common.primary}
        hidden={!leftFunc}
        onClick={leftFunc}
        startIcon={leftEndIcon}
      >
        {leftText}
      </LeftButton>
      {rightFunc && (
        <RightButton
          disabled={rightLoading}
          hidden={!rightFunc}
          onClick={rightFunc}
          endIcon={rightLoading ? null : rightEndIcon}
        >
          {rightLoading && <Loading></Loading>}
          {!rightLoading && rightText}
        </RightButton>
      )}
    </CFooter>
  );
}

HorizontalTwoActionFooter.propTypes = {
  rightFunc: PropTypes.func,
  rightText: PropTypes.string,
  rightEndIcon: PropTypes.node,
  rightLoading: PropTypes.bool,
  leftFunc: PropTypes.func,
  leftText: PropTypes.string,
  leftEndIcon: PropTypes.node,
};

export default HorizontalTwoActionFooter;
