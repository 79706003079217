import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import HorizontalTwoActionFooter from '../Footer/HorizontalTwoActionFooter';
import { PAGE_NAME as LAST_PAGE } from './SelectDatePage';
import { PAGE_NAME as NEXT_PAGE } from './SelectTimeSlotPage';
import Menu from '../1TalkDesign/menu';
import Radios from '../1TalkDesign/Radios';
import { getURLSearchParamByKey } from '../../utils';
import { t } from 'src/i18n/config';

const SelectContainer = styled.div`
  margin: 24px 16px 0 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const Title = styled.div`
  font-family: NotoSansCJKtc;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.08px;
  color: #202d31;
`;

const CMenu = styled(Menu)`
  margin-top: 8px;
`;

SelectDoctors.defaultProps = {
  schedules: {},
  subjects: [],
  appointment: {
    date: '',
  },
};
function SelectDoctors({
  schedules,
  subjects: originalSubjects,
  onChangeStep,
  appointment,
}) {
  const selectDisplay = getURLSearchParamByKey('selectDisplay');
  const doctorOrdering = getURLSearchParamByKey('doctorOrdering');
  const subjectOrdering = getURLSearchParamByKey('subjectOrdering');
  const [selectedSubject, setSelectedSubject] = useState();
  const [selectedDoctor, setSelectedDoctor] = useState();

  const subjects = useMemo(() => {
    if (subjectOrdering) {
      return originalSubjects.sort((a, b) => {
        if (subjectOrdering === 'asc') {
          return a.id.localeCompare(b.id);
        }
        if (subjectOrdering === 'desc') {
          return b.id.localeCompare(a.id);
        }
        return 0;
      });
    }

    return originalSubjects;
  }, [originalSubjects, subjectOrdering]);

  const doctors = useMemo(() => {
    const list = Object.keys(
      (schedules[appointment.date] || {})[selectedSubject] || {}
    ).map((s) => {
      return {
        id: s,
        name: s,
        value: s,
      };
    });

    if (doctorOrdering) {
      return list.sort((a, b) => {
        if (doctorOrdering === 'asc') {
          return a.id.localeCompare(b.id);
        }
        if (doctorOrdering === 'desc') {
          return b.id.localeCompare(a.id);
        }
        return 0;
      });
    }

    return list;
  }, [appointment.date, schedules, selectedSubject, doctorOrdering]);

  const handleBack = () => {
    onChangeStep(LAST_PAGE);
  };

  const handleNext = () => {
    onChangeStep(NEXT_PAGE, {
      schedules,
      appointment: {
        ...appointment,
        subject: selectedSubject,
        doctor: selectedDoctor,
      },
    });
  };

  const handleSelectSubject = (item) => {
    const selectedValue = item?.value || item;
    setSelectedSubject(selectedValue);
    if (selectedValue !== selectedSubject) {
      setSelectedDoctor(
        Object.keys((schedules[appointment.date] || {})[selectedValue] || {})[0]
      );
    }
  };

  const handleSelectDoctor = (item) => {
    const selectedValue = item?.value || item;
    setSelectedDoctor(selectedValue);
  };

  useEffect(() => {
    setSelectedDoctor(doctors[0]?.id);
  }, [doctors]);

  useEffect(() => {
    setSelectedSubject(subjects[0]?.id);
  }, [subjects]);

  return (
    <React.Fragment>
      <SelectContainer>
        <Title>{t('selectDepartment')}</Title>
        {selectDisplay === 'radio' ? (
          <Radios
            options={subjects}
            defaultValue={selectedSubject}
            onChange={handleSelectSubject}
          />
        ) : (
          <CMenu
            items={subjects}
            name={selectedSubject}
            onClickItem={handleSelectSubject}
          />
        )}
      </SelectContainer>
      <SelectContainer>
        <Title>{t('selectDoctor')}</Title>
        {selectDisplay === 'radio' ? (
          <Radios
            options={doctors}
            defaultValue={selectedDoctor}
            onChange={handleSelectDoctor}
          />
        ) : (
          <CMenu
            items={doctors}
            name={selectedDoctor}
            onClickItem={handleSelectDoctor}
          />
        )}
      </SelectContainer>

      <HorizontalTwoActionFooter
        rightFunc={handleNext}
        rightText={t('next')}
        rightEndIcon={<i className="ri-arrow-right-s-line"></i>}
        leftFunc={handleBack}
        leftText={t('back')}
        leftEndIcon={<i className="ri-arrow-left-s-line"></i>}
      ></HorizontalTwoActionFooter>
    </React.Fragment>
  );
}

SelectDoctors.propTypes = {};

export default SelectDoctors;
export const PAGE_NAME = 'SelectDoctors';
export const prepareProps = async (state) => {
  const { schedules, selectedDateString } = state;
  const subjectMap = schedules[selectedDateString];
  const subjects = Object.keys(subjectMap).map((s) => {
    return {
      id: s,
      name: s,
      value: s,
    };
  });
  return {
    schedules,
    subjects,
    appointment: {
      date: selectedDateString,
    },
  };
};
