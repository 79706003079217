import React, { useEffect, useState } from 'react';
import { t } from 'src/i18n/config';
import { Helmet } from 'react-helmet';
import Layout from 'components/layout';
import { getUserInfo } from '../../services/user.service';
import SelectDatePage, {
  PAGE_NAME as SelectDatePageName,
  prepareProps as prepareDateProps,
} from 'components/appointments/SelectDatePage';
import SelectDoctorsPage, {
  PAGE_NAME as SelectDoctorsPageName,
  prepareProps as prepareDoctorsProps,
} from 'components/appointments/SelectDoctorsPage';
import SelectTimeSlotPage, {
  PAGE_NAME as SelectTimeSlotPageName,
  prepareProps as prepareTimeSlotProps,
} from 'components/appointments/SelectTimeSlotPage';
import SelectPatientsPage, {
  PAGE_NAME as SelectPatientsPageName,
  prepareProps as preparePatientsProps,
} from 'components/appointments/SelectPatientsPage';
import ConfirmPage, {
  PAGE_NAME as ConfirmPageName,
  prepareProps as prepareConfirmProps,
} from 'components/appointments/ConfirmPage';
import SuccessPage, {
  PAGE_NAME as SuccessPageName,
  prepareProps as prepareSuccessProps,
} from 'components/appointments/SuccessPage';
import FailedPage, {
  PAGE_NAME as FailedPageName,
  prepareProps as prepareFailedProps,
} from 'components/appointments/FailedPage';

const stepMap = {
  [SelectDatePageName]: { page: SelectDatePage, func: prepareDateProps },
  [FailedPageName]: { page: FailedPage, func: prepareFailedProps },
  [SuccessPageName]: { page: SuccessPage, func: prepareSuccessProps },
  [ConfirmPageName]: { page: ConfirmPage, func: prepareConfirmProps },
  [SelectPatientsPageName]: {
    page: SelectPatientsPage,
    func: preparePatientsProps,
  },
  [SelectTimeSlotPageName]: {
    page: SelectTimeSlotPage,
    func: prepareTimeSlotProps,
  },
  [SelectDoctorsPageName]: {
    page: SelectDoctorsPage,
    func: prepareDoctorsProps,
  },
};

const Create = ({ location = {}, vendor }) => {
  const { state = {} } = location;
  const [props, setProps] = useState(null);
  const [step, setStep] = useState((state && state.step) || SelectDatePageName);
  const [lastState, setlastState] = useState({});
  const [isPreparingProps, setIsPreparingProps] = useState(false);
  const { page: Page } = stepMap[step];

  const handleStepChange = (nextStep, lastState) => {
    setStep(nextStep);
    setlastState(lastState);
  };

  useEffect(() => {
    setIsPreparingProps(true);
    const { func } = stepMap[step];
    func(lastState)
      .then((props) => setProps(props))
      .catch((e) =>
        handleStepChange(FailedPageName, { message: JSON.stringify(e) })
      )
      .finally(() => setIsPreparingProps(false));
  }, [step, lastState]);

  useEffect(() => {
    // 當使用者沒有病患資料時，導向新增病患頁面，並在新增病患後導回預約頁面
    getUserInfo().then((user) => {
      if (!user?.patients?.length) {
        const currentUrl = window.location.href;
        const goBack = `${currentUrl.search ? '&' : '?'}goBack=true`;
        window.location.replace(
          currentUrl.replace('/appointments/create', '/users/self') + goBack
        );
      }
    });
  }, []);

  return (
    <Layout>
      <Helmet>
        <title>{t('onlineAppointment')}</title>
      </Helmet>
      <Page
        {...props}
        loading={props == null}
        isPreparingProps={isPreparingProps}
        onChangeStep={handleStepChange}
        vendor={vendor}
      ></Page>
    </Layout>
  );
};

export default Create;
