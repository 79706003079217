import React, { useEffect } from 'react';
import styled from 'styled-components';
import CheckLogo from 'static/images/icons-ic-system-checkbox-circle-fill.png';
import { getSuccessOpenPageUrl } from 'src/utils';
import Modal from 'components/Modal';
import { t } from 'src/i18n/config';

const Container = styled.div`
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const Img = styled.img`
  width: 64px;
  height: 64px;
  object-fit: contain;
`;

const Subject = styled.div`
  margin-top: 8px;
`;

const DoctorName = styled.div`
  margin-top: 8px;
  font-size: 1.375rem;
  font-weight: 500;
  letter-spacing: 0.07px;
`;

const Patient = styled.div`
  margin-top: 8px;
  font-weight: 500;
`;
const DateTimeString = styled.div`
  margin-top: 10px;
  font-weight: 500;
  font-size: ${(props) => `${props.size}px` || 'inherit'};
`;

const SectionContainer = styled.div`
  margin-top: 16px;
  border-top: ${(props) => props.theme.boxBorder.primary};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Title = styled.div`
  margin-top: 8px;
  font-size: 1rem;
  line-height: 1.54;
  color: ${(props) => props.theme.colors.textSecondary};
`;
const Value = styled.div`
  margin-top: 8px;
  font-size: 1.625rem;
  font-weight: 500;
  line-height: 1.33;
  letter-spacing: normal;
`;

function InfoSection({ title, value }) {
  return (
    <SectionContainer>
      <Title>{title}</Title>
      <Value>{value}</Value>
    </SectionContainer>
  );
}

function SuccessPage({
  subject,
  doctor,
  patient,
  slot,
  roomId,
  roomID,
  booked,
}) {
  const redirectLink = getSuccessOpenPageUrl();
  const { seq, appointmentTime } = booked;
  const dateTimeString = `${slot.slotId} ${
    appointmentTime !== '' ? '' : slot.title
  }`;
  const timeString =
    appointmentTime !== '' ? `${slot.title} ${appointmentTime || ''}` : '';

  useEffect(() => {
    if (redirectLink) {
      console.log('redirectLink:', redirectLink);
      setTimeout(() => {
        window.location.replace(redirectLink);
      }, 1000);
    }
  }, [redirectLink]);

  return (
    <React.Fragment>
      <Modal>
        <Container>
          <Img src={CheckLogo} alt="" />
          <DoctorName>{t('doctor', { name: doctor })}</DoctorName>
          <Subject>{subject}</Subject>
          <Patient>
            {patient.isSelf
              ? `${patient.name} ${t('yourself')}`
              : `${patient.name} ${
                  patient.alias != null ? `(${patient.alias})` : ''
                }`}
          </Patient>
          <DateTimeString>{dateTimeString}</DateTimeString>
          {timeString !== '' && (
            <DateTimeString size="20">{timeString}</DateTimeString>
          )}
          <InfoSection title={t('room')} value={roomId || roomID}></InfoSection>
          <InfoSection
            title={t('appointmentNumber')}
            value={`${seq}${t('number')}`}
          ></InfoSection>
        </Container>
      </Modal>
    </React.Fragment>
  );
}

SuccessPage.propTypes = {};

SuccessPage.defaultProps = {
  patient: {},
  slot: {},
  booked: {},
};

export default SuccessPage;
export const PAGE_NAME = 'SuccessPage';
export const prepareProps = async (state) => {
  return {
    patient: {},
    ...state,
  };
};
